<template>
  <v-container>
    <v-card color="grey lighten-5" elevation="0">
      <v-card-title>
        <h3 class="font-weight-black display-0 basil--text">
          CAJA
        </h3>
      </v-card-title>
    </v-card><br>
    <v-row>
      <v-col
        cols="12"
        lg="2"
      >
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFormatted"
              label="Buscar por fecha"
              persistent-hint
              append-icon="mdi-calendar"
              v-bind="attrs"
              outlined
              dense
              @blur="date = parseDate(dateFormatted)"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            @input="menu1 = false"
            color="green lighten-1"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" lg="3" v-if="!userState">
        <v-btn
          dark
          color="lime darken-4"
          large
          @click="openBox"
        >
          <v-icon dark class="mr-2">
            mdi-cash-lock-open
          </v-icon>
          APERTURAR CAJA
        </v-btn>
      </v-col>
      <v-col cols="12" lg="3" v-else class="text-right">
        <v-btn
          dark
          color="primary"
          large
          @click="sale"
        >
          <v-icon dark class="mr-2">
            mdi-sale
          </v-icon>
          GENERAR VENTA
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="cashes"
      class="elevation-1"
      :footer-props="{ 'items-per-page-options': [5, 10, 20, 50] }"
      :server-items-length="totalCashes"
      :options.sync="options"
    >
      <template v-slot:item.state="{ item }">
        <v-chip
          :color="getColor(item.state)"
          dark
          x-small
          v-if="item.state === 1"
        >
          Abierto
        </v-chip>
        <v-chip
          :color="getColor(item.state)"
          dark
          x-small
          v-if="item.state === 0"
        >
          Cerrado
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="item.state === 1"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="closeItem(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
          small
          @click="revertItem(item)"
          v-if="user[0].role_id === 1 && cashState === false"
        >
          mdi-reply
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="getReport(item.id)"
        >
          mdi-file-pdf
        </v-icon>
      </template>
      <template v-if="progress" v-slot:no-data>
        <div class="text-center">
          <v-progress-circular
            :size="40"
            :width="3"
            color="green"
            indeterminate
          ></v-progress-circular>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-toolbar
          color="lime darken-4"
          dark
          elevation="0"
        ><v-icon dark class="mr-2" size="40">
          mdi-cash-lock-open
        </v-icon>ACTUALIZAR CAJA</v-toolbar><br>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="12"
              >
                <v-autocomplete
                  v-model="entity_id"
                  :items="entities"
                  label="Sucursal"
                  outlined
                  dense
                  disabled
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="12"
              >
                <v-text-field
                  v-model="responsable"
                  label="Nombre"
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="editedItem.initial_balance"
                  label="Saldo inicial"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="12"
              >
                <v-textarea
                  v-model="editedItem.observations"
                  label="Observaciones"
                  outlined
                  dense
                  rows="2"
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                lg="5"
              >
                <v-menu
                  ref="menu1"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      label="Fecha"
                      persistent-hint
                      v-bind="attrs"
                      outlined
                      disabled
                      append-icon="mdi-calendar"
                      dense
                      @blur="opening_date = parseDate(dateFormatted)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="opening_date"
                    no-title
                    @input="menu2 = false"
                    color="green lighten-1"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="lime darken-4"
            text
            @click="closeEdit"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="lime darken-4"
            text
            @click="updateBox"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog1" persistent max-width="500px">
      <v-card>
        <v-toolbar
          color="lime darken-4"
          dark
          elevation="0"
        ><v-icon dark class="mr-2" size="40">
          mdi-cash-lock-open
        </v-icon>APERTURA DE CAJA</v-toolbar><br>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="12"
              >
                <v-autocomplete
                  v-model="entity_id"
                  :items="entities"
                  label="Sucursal"
                  outlined
                  disabled
                  dense
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="12"
              >
                <v-text-field
                  v-model="responsable"
                  label="Nombre"
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="editedItem.initial_balance"
                  label="Saldo inicial"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="12"
              >
                <v-textarea
                  v-model="editedItem.observations"
                  label="Observaciones"
                  outlined
                  dense
                  rows="2"
                  :error-messages="errors.get('name')"
                  @keydown="errors.clear('name')"
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                lg="5"
              >
                <v-menu
                  ref="menu1"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      label="Fecha"
                      persistent-hint
                      v-bind="attrs"
                      outlined
                      disabled
                      append-icon="mdi-calendar"
                      dense
                      @blur="opening_date = parseDate(dateFormatted)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="opening_date"
                    no-title
                    @input="menu2 = false"
                    color="green lighten-1"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="lime darken-4"
              text
              @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="lime darken-4"
              text
              @click="saveBox"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" persistent max-width="600px">
      <v-card>
        <v-toolbar
            color="lime darken-4"
            dark
            elevation="0"
        >
          <v-icon dark class="mr-2" size="40">
            mdi-cash-lock
          </v-icon>CIERRE DE CAJA
          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              @click="closeBox"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <br>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                sm="12"
                xs="12"
                md="12"
              >
                <span class="mr-4">FECHA DE APERTURA</span>  {{this.editedItem.opening_date}}<br>
                <span class="mr-10">FECHA DE CIERRE</span>  {{this.editedItem.closing_date}}<br>
                <span class="mr-15">SALDO INICIAL</span>  {{this.editedItem.initial_balance}}<br>
                <span class="mr-15">MONTO TOTAL</span>  {{this.editedItem.final_balance}}
              </v-col>
            </v-row>
            <br>
            <v-row>
              <v-col
                sm="12"
                xs="12"
                md="5"
              >
                <v-simple-table  class="elevation-1">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">
                        TIPO
                      </th>
                      <th class="text-left">
                        MONTO
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(amountVaucher, index) in amountVouchers" :key="index">
                      <td>{{amountVaucher.type.name}}</td>
                      <td>{{amountVaucher.total}}</td>
                    </tr>
                    <tr>
                      <td>TOTAL</td>
                      <td>{{editedItem.final_balance}}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col
                  sm="12"
                  xs="12"
                  md="7"
              >
                <v-simple-table  class="elevation-1">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">
                        COMPROBANTE
                      </th>
                      <th class="text-left">
                        MONTO
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(amountPayment, index) in amountPayments" :key="index">
                      <td>{{ amountPayment.buy.name }}</td>
                      <td>{{ amountPayment.total }}</td>
                    </tr>
                    <tr>
                      <td>TOTAL</td>
                      <td>{{editedItem.final_balance}}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-textarea
                  v-model="editedItem.observations"
                  outlined
                  label="Observaciones"
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions  v-if="editedItem.state === 1">
          <v-spacer></v-spacer>
          <v-btn
            color="lime darken-4"
            text
            @click="closeBox"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="lime darken-4"
            text
            @click="saveCloseBox()"
          >
            Cerrar Caja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="text-center ma-2">
      <v-snackbar
        v-model="snackbar"
      >
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import {mapActions, mapState, mapGetters} from "vuex";
import {Errors} from "../../plugins/errors";
import moment from 'moment'
import PDF from 'jspdf'
import 'jspdf-autotable';

export default {
  name: "Box",
  data () {
    return {
      headers: [
        { text: 'ID',              value: 'id',                          sortable: false, class: "success white--text", width: "50px"},
        { text: 'RESPONSABLE',     value: 'assignment.person.full_name', sortable: false, class: "success white--text", width: "50px"},
        { text: 'FECHA APERTURA',  value: 'opening_date',                sortable: false, class: "success white--text", width: "50px"},
        { text: 'FECHA CIERRE',    value: 'closing_date',                sortable: false, class: "success white--text", width: "50px"},
        { text: 'SALDO INICIAL',   value: 'initial_balance',             sortable: false, class: "success white--text", width: "50px"},
        { text: 'TOTAL DE VENTAS', value: 'final_balance',               sortable: false, class: "success white--text", width: "50px"},
        { text: 'OBSERVACIONES',   value: 'observations',                sortable: false, class: "success white--text", width: "50px"},
        { text: 'ESTADO',          value: 'state',                       sortable: false, class: "success white--text", width: "50px"},
        { text: 'ACCIONES',        value: 'actions',                     sortable: false, class: "success white--text", width: "50px"},
      ],
      snackbar: false,
      text: 'Operación realizada con éxito !!!',
      progress: false,
      dialog: false,
      dialog1: false,
      dialog2: false,
      errors: new Errors(),
      date: moment(String(new Date())).format('YYYY-MM-DD hh:mm:ss'),
      opening_date: new Date().toISOString().substr(0, 10),
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      menu1: false,
      menu2: false,
      responsable: this.$store.state.auth.user[0].person.full_name,
      entity_id: this.$store.state.auth.user[0].area.entity.id,
      person_id: this.$store.state.auth.user[0].person_id,
      userState: 0,
      show: false,
      listReport: [],
      countRow: 0,
      options: {},
      editedItem: {
        id: '',
        opening_date: '',
        closing_date: '',
        initial_balance: 0.0,
        final_balance: '',
        observations: '',
        state: 0
      },
      amountVouchers: [],
      amountPayments: []
    }
  },
  computed: {
    opening: {
      get () {
        return this.opening_date
      },
      set (val) {
        this.opening_date = val
      }
    },
    ...mapState('box', ['cashes', 'entities', 'totalCashes']),
    ...mapGetters('box', ['cashState']),
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['getState']),
  },
  watch: {
    date () {
      this.dateFormatted = this.formatDate(this.date)
    },
    opening () {
      this.dateFormatted = this.formatDate(this.opening_date)
    },
    options: {
      async handler() {
        await this.fetchBox();
      }
    }
  },
  created() {
    this.fetchBox()
    this.getStateUser()
  },
  methods: {
    ...mapActions({
      getBox: 'box/getBox'
    }),
    getColor (state) {
      if (state === 1) return 'primary'
      else if (state === 0) return 'red'
      else return ''
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async fetchBox () {
      const params = { ...this.options }
      await this.getBox(params)
    },
    editItem (item) {
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    closeItem (item) {
      this.editedItem = Object.assign({}, item)
      this.dialog2 = true
      this.getBoxTotal()
      this.getAmountVoucher(item.id)
      this.getAmountPayment(item.id)
    },
    saveBox () {
      const createBox = {
        'opening_date'      : this.editedItem.opening_date,
        'initial_balance'   : this.editedItem.initial_balance,
        'observations'      : this.editedItem.observations,
        'person_id'         : this.$store.state.auth.user[0].person.id,
        'area_assignment_id': this.$store.state.auth.user[0].id,
        'entity_id'         : this.user[0].area.entity.id
      }

      axios.post('/api/ticket/box', createBox)
          .then(() => {
            this.getBox()
            this.reset()
            this.close()
            this.getStateUser()
            this.snackbar = true;
          },error => this.errors.record(error.response.data.errors));
    },
    updateBox () {
      const updateBox = {
        'id'                : this.editedItem.id,
        'opening_date'      : this.editedItem.opening_date,
        'initial_balance'   : this.editedItem.initial_balance,
        'observations'      : this.editedItem.observations,
        'person_id'         : this.$store.state.auth.user[0].person.id,
        'area_assignment_id': this.$store.state.auth.user[0].id,
        'entity_id'         : this.user[0].area.entity.id
      }

      axios.put('/api/cash', updateBox)
          .then(() => {
            this.getBox()
            this.reset()
            this.closeEdit()
            this.getStateUser()
            this.snackbar = true;
          },error => this.errors.record(error.response.data.errors));
    },
    openBox () {
      this.dialog1 = true
    },
    close () {
      this.dialog1 = false
      this.editedItem.observations = ''
    },
    reset () {
      this.editedItem.id =  ''
      this.editedItem.opening_date =  ''
      this.editedItem.closing_date =  ''
      this.editedItem.initial_balance =  ''
      this.editedItem.final_balance =  ''
      this.editedItem.observations =  ''
    },
    sale () {
      this.$router.push("/sell").catch(()=>{});
    },
    closeBox () {
      this.dialog2 = false
    },
    closeEdit () {
      this.dialog = false
    },
    saveCloseBox () {
      const updateBox = {
        'id':              this.editedItem.id,
        'final_balance':   this.editedItem.final_balance,
        'observations':    this.editedItem.observations
      }

      axios.post('/api/ticket/box/close', updateBox)
          .then(() => {
            this.getBox()
            this.getStateUser()
            this.reset()
            this.dialog2 = false
          })
          .catch(error => console.log(error))
    },
    getBoxTotal () {
      axios.get(`/api/ticket/box/total/${this.editedItem.id}`)
          .then((response) => {
            if (response.data.data.total) {
              this.editedItem.final_balance = parseFloat(response.data.data.total).toFixed(2)
            } else {
              this.editedItem.final_balance = 0
            }
          })
          .catch(error => console.log(error))
    },
    getAmountVoucher (id) {
      const request = {
        'cash_id': id
      }

      axios.post('/api/ticket/amount/voucher', request)
          .then((response) => {
            this.amountVouchers = response.data.data
          })
          .catch(error => console.log(error))
    },
    getAmountPayment (id) {
      const request = {
        'cash_id': id
      }

      axios.post('/api/ticket/amount/payment', request)
          .then((response) => {
            this.amountPayments = response.data.data
          })
          .catch(error => console.log(error))
    },
    getStateUser () {
      axios.get('/api/ticket/box/state')
          .then((response) => {
            this.userState = response.data.data
          })
          .catch(error => console.log(error))
    },
    revertItem (item) {
      const request = {
        'id': item.id
      }

      axios.post('/api/reverse', request)
          .then(() => {
            this.getBox()
          })
          .catch(error => console.log(error))
    },
    getReport (id) {
      setTimeout(() => {
        axios.get(`api/report/box/${id}`)
          .then((response) => {
            this.listReport = response.data.details
            this.responsable=response.data.responsable
            this.totalVentas=response.data.totalVentas
            this.infoCaja=response.data.infoCaja

            this.createPDF()
          })
          .catch(error => console.log(error))
      }, 1000)
    },
    headRows() {
      return [{
        prefijo: 'PREFIJO',
        numero: 'NÚMERO',
        date: 'FECHA',
        name: 'PRODUCTO',
        quantity: 'CANT',
        sale_unit: 'COSTO',
        total: 'TOTAL',
        nameTypeBuy:'TIPO DE COMPRA'
      }];
    },
    bodyRows(rowCount, pdfBody) {
      rowCount = rowCount || 10;
      let body = [];
      for (let i = 0; i < pdfBody.length; i++) {
        body.push({
          prefijo: pdfBody[i].prefijo,
          numero: pdfBody[i].numero,
          date: pdfBody[i].date,
          name: pdfBody[i].name,
          quantity: pdfBody[i].quantity,
          sale_unit:pdfBody[i].sale_unit,
          total: pdfBody[i].total,
          nameTypeBuy:pdfBody[i].nameTypeBuy
        });
      }
      return body;
    },
    createPDF () {
      let doc = new PDF("p", "pt", "letter");
      let header = () => {

        let header_1 = () => {
          doc.setFontSize(10);
          doc.text(39, 40, 'REPORTE DE CAJA')
        };
        doc.autoTable({didDrawPage : header_1});

        let header_2 = () => {
          doc.setFontSize(8);
          doc.text(39, 50, 'SysPharma 1.0')
        };
        doc.autoTable({didDrawPage : header_2});

        let today = new Date();
        let header_3 = () => {
          doc.setFontSize(9);
          doc.text(450, 30, `Fecha: ${today.getDate()<10 ? '0'+today.getDate() : today.getDate()}/${today.getMonth()<10 ? '0'+today.getMonth():today.getMonth()}/${today.getFullYear()<10 ? '0'+today.getFullYear():today.getFullYear()}`)
        };
        doc.autoTable({didDrawPage : header_3});

        let header_4 = () => {
          doc.setFontSize(9);
          doc.text(450, 40, `Hora: ${today.getHours()<10 ? '0'+today.getHours() : today.getHours()}:${today.getMinutes()<10 ? '0'+today.getMinutes():today.getMinutes()}:${today.getSeconds()<10 ? '0'+today.getSeconds():today.getSeconds()}`)
        };
        doc.autoTable({didDrawPage : header_4});

        let header_5 = () => {
          doc.setFontSize(9);
          doc.text(450, 50, `Página ${doc.internal.getNumberOfPages()}`);
        };
        doc.autoTable({didDrawPage : header_5});

        let header_6 = () => {
          doc.setFontSize(9);
          doc.text(39, 80, `RESPONSABLE:  ${this.responsable[0].firstName+' '+this.responsable[0].lastName}`)
        };
        doc.autoTable({didDrawPage : header_6});

        let header_7 = () => {
          doc.setFontSize(9);
          doc.text(39, 120, `Observaciones:  ${this.infoCaja[0].observations}`)
        };
        doc.autoTable({didDrawPage : header_7});

        let header_8 = () => {
          doc.setFontSize(10);

          const number = parseFloat(this.totalVentas[0].totalVentas).toFixed(2);
          doc.text(450, 120, `TOTAL VENTAS:  ${number}`)

        };
        doc.autoTable({didDrawPage : header_8});

        let header_9 = () => {
          doc.setFontSize(8);
          doc.text(39, 90, `Apertura de Caja:  ${this.infoCaja[0].opening_date}`)
        };
        doc.autoTable({didDrawPage : header_9});

        let header_10 = () => {
          doc.setFontSize(8);
          doc.text(39, 100, `Cierre de Caja:  ${this.infoCaja[0].closing_date}`)
        };
        doc.autoTable({didDrawPage : header_10});
        let header_11 = () => {
          doc.setFontSize(8);
          doc.text(39, 110, `Monto Inicial:  ${this.infoCaja[0].initial_balance}`)
        };
        doc.autoTable({didDrawPage : header_11});
      };
      console.error = () => {};
      doc.autoTable({
        didDrawPage : header,
        margin: {top: 130},
        styles: {
          cellPadding: 1,
          fontSize: 8,
          halign: 'center'
        },
        theme: 'grid',
        head: this.headRows(),
        body: this.bodyRows(this.countRow, this.listReport),

        bodyStyles: {
          margin: 20,
          fontSize: 10
        },
        columnStyles: {
          0: {cellWidth: 40},
          1: {cellWidth: 50},
          2: {cellWidth: 90, fontSize: 9},
          3: {cellWidth: 180, halign: 'left'},
          4: {cellWidth: 40, valign: 'middle', halign: 'center'},
          5: {cellWidth: 50, valign: 'middle', halign: 'right'},
          6: {cellWidth: 40, valign: 'middle', halign: 'right'},
          7: {cellWidth: 40, fontSize: 7, valign: 'middle', halign: 'center'},
        }
      });

      doc.save(`Caja${this.responsable[0].lastName}${this.infoCaja[0].opening_date}.pdf`);
    },
  }
}
</script>

<style scoped>
.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}

.inputs-container,
.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}
.theme--light.v-input {
  padding-top: 0;
}
.v-input__slot {
  margin-bottom: 0;
}
.v-progress-circular {
  margin: 1rem;
}
</style>